.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  font-family: "Lato";
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 9px 20px;
  letter-spacing: 0.6px;
  font-size: 10px;
  line-height: normal;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #ffffff;
  // solid
  &__link-alt {
    color: #374352;
  }

  &__link {
    color: var(--brand-primary);
    text-decoration: none;
  }

  @each $class, $value in $theme {
    &__#{$class} {
      background-color: var(--#{$class});
      border-color: var(--#{$class});
      > span {
        color: inherit;
      }

      &:hover {
        background-color: darken($value, 5%);
        border-color: darken($value, 5%);
      }

      &:disabled {
        background-color: var(--light-grey);
        border-color: rgba(55, 67, 82, 0.1);
        color: rgba(55, 67, 82, 0.4);
        .glyph {
          -webkit-filter: invert(23%)
            sepia(47%)
            saturate(274%)
            hue-rotate(172deg)
            brightness(92%)
            contrast(90%);
          filter: invert(23%)
            sepia(47%)
            saturate(274%)
            hue-rotate(172deg)
            brightness(92%)
            contrast(90%);
        }
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  &__primary:not(:disabled) {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
    &:hover {
      background-color: var(--brand-primary-darken);
      border-color: var(--brand-primary-darken);
    }
  }
  // outline

  &__outline {
    border-radius: 1px;
  }

  @each $class, $value in $theme {
    &__outline--#{$class} {
      background-color: #ffffff;
      border-color: var(--#{$class});
      color: var(--#{$class});

      &:hover {
        background-color: darken($value, 5%) !important;
        border-color: darken($value, 5%);
        color: #ffffff;
      }

      &:disabled {
        color: var(--grey);
        border-color: var(--grey);
        &:hover {
          cursor: not-allowed;
          background-color: transparent !important;
        }
      }
    }
  }

  &__outline--primary:not(:disabled) {
    border-color: var(--brand-primary);
    color: var(--brand-primary);
    &:hover {
      background-color: var(--brand-primary-darken) !important;
      border-color: var(--brand-primary-darken);
      color: #fff !important;
    }
  }

  .glyph {
    transform: scale(0.7);
  }

  &__group {
    display: flex;
    justify-content: space-evenly;
    padding: 4px;
    border: 1px solid rgba(55, 67, 82, 0.1);
    .btn {
      flex-grow: 1;
      &__link {
        color: var(--brand-primary);
      }
    }
  }

  &--sm,
  &--md {
    font-size: 10px;
    letter-spacing: 0.5px;
  }

  &--sm {
    padding: 5px 20px;
  }

  &--md {
    padding: 8px 10px;
  }

  &--lg {
    padding: 17px 30px;
  }

  &--xl {
    font-size: 21px;
    padding: 12px 24px;
  }

  &--xl-alt {
    font-size: 16px;
    padding: 28px;
    font-weight: normal;
  }

  &--rounded {
    border-radius: 8px;
  }

  &--text-init {
    font-weight: normal;
    text-transform: initial;
  }

  &--block {
    width: 100%;
  }

  &--loading {
    padding: 6px 25px;
  }
}

button:focus {
  outline: 0;
}

button:hover {
  cursor: pointer;
}
