label {
  display: block;
  font-weight: bold;
}
input:focus {
  outline: 0;
}
.form__group--has-error {
  .drop-field {
    border: 1px solid var(--danger) !important;
    color: var(--danger);
  }
}

.strike-behind {
  display: block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px;

  > span {
    display: inline-block;
    padding: 2px 15px;
    background-color: #ffffff;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: map-get($input, "border-color");
    top: 50%;
  }
}

.content-wrapper {
  // margin: 24px;
  height: 100%;
  min-height: calc(100vh - 180px);
}

.form__checkbox.mat-checkbox {
  .mat-checkbox-layout {
    text-transform: none !important;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
