.callout {
  margin: 24px 0;
  padding: 20px;
  border-left: 4.5px solid #eee;
  position: relative;
  width: 100%;

  &.alert {
    border-width: 0px 0px 0px 4.5px;
  }

  &.mar-lg {
    margin: 48px 0;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: normal;
    font-weight: bold;
  }

  p {
    font-size: 12px;
  }

  .helper-text {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0px;
    padding-right: 48px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @each $class in map-keys($theme) {
    &__#{$class} {
      background-color: rgba(map-get($theme, #{$class}), 0.1) !important;
      border-color: var(--#{$class});
      h4 {
        color: var(--#{$class});
      }
      p {
        color: rgba(var(--dark), 0.7);
      }
    }
  }

  &__primary {
    border-color: var(--brand-primary);
    background-color: var(--brand-primary-lighten) !important;
    h4 {
      color: var(--brand-primary);
    }
  }
}
