.ng-select.ng-select-multiple.form__control.userSelect {
  height: auto;
  max-height: 105px;
  overflow: scroll;
  padding: 4px 8px;

  .ng-select-container {
    .ng-value-container {
      .ng-value {
        position: relative;
        background-color: rgba(55, 67, 82, 0.9);
        color: #fff;
        padding: 2px 8px;
        border-radius: 8px;
        font-size: 16px;
        margin: 4px;
        display: flex;
        align-items: center;
        .ng-value-icon {
          order: 1;
          font-size: 20px;
          margin-left: 8px;
        }
        .ng-value-label {
          div {
            .glyph {
              display: none;
            }
          }
          span {
            display: none;
          }
        }
      }

      .ng-input {
        input {
          font-size: 16px;
          height: 33px;
        }
      }
    }
    .ng-arrow-wrapper {
      display: none;
    }
  }
}

.userSelect__result-container {
  position: relative;

  .userSelect {
    height: auto;
    max-height: 176px;
    overflow-y: auto;
    position: relative;
    top: 0px !important;
    left: 0px;
    background-color: #fff !important;
    border: 0px;
    padding: 0px;

    .ng-dropdown-panel-items {
      .ng-option {
        .ng-option-label {
          display: flex;
          justify-content: space-between;
          align-items: center;

          padding: 10px 0px;
          background-color: #fff;
          > div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .glyph {
              transform: scale(1);
              margin-right: 12px;
            }
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #979797;
        }
      }
    }
  }
}
