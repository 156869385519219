$glyph-size: 24;
$glyph-names: (
  "save",
  "favorite",
  "stop",
  "record",
  "music",
  "email",
  "emoji-smiley",
  "send",
  "money-bill",
  "operator",
  "edit",
  "number",
  "money",
  "home",
  "clipboard",
  "cart",
  "pin",
  "headquarters",
  "dashboard",
  "settings",
  "status",
  "notification",
  "trash",
  "location",
  "link",
  "calendar",
  "apis-default",
  "credit-card",
  "attatchment",
  "image",
  "directory",
  "document",
  "ellipse",
  "menu-hamburger",
  "filter",
  "list",
  "dialpad",
  "menu-grid",
  "devices-transfer-mobile-to-mobile",
  "devices-transfer-to-mobile",
  "devices-mobile",
  "devices-desktop",
  "lock-unlocked",
  "lock",
  "view-hide",
  "view-show",
  "zoom-out",
  "zoom-in",
  "search",
  "indicators-pause",
  "indicators-play",
  "indicators-support",
  "indicators-info",
  "indicators-help",
  "indicators-warning",
  "language",
  "contact",
  "yes-circle",
  "no-circle",
  "blocked",
  "time",
  "arrows-logs",
  "arrows-refresh",
  "transfer",
  "merge",
  "arrows-minimize",
  "arrows-contract",
  "arrows-expand",
  "arrows-left-and-right",
  "arrows-reassign",
  "arrows-down-little",
  "arrows-up-little",
  "arrows-down-shift",
  "arrows-up-shift",
  "caret-left",
  "caret-right",
  "caret-down",
  "caret-up",
  "arrows-inbound",
  "arrows-outbound",
  "arrows-download",
  "arrows-upload",
  "arrows-left",
  "arrows-right",
  "arrows-down",
  "arrows-up",
  "arrows-logout",
  "arrows-collapse",
  "minus-subtract",
  "plus-add",
  "no-close",
  "yes-check",
  "person-team-remove",
  "person-team-add",
  "person-team-default",
  "person-remove",
  "person-add",
  "person-default",
  "volume-off",
  "volume-up",
  "volume-down",
  "conference-active",
  "conference-end",
  "conference-start",
  "conference-default",
  "fax-error",
  "fax-inbound",
  "fax-outbound",
  "fax-default",
  "video-hide",
  "video-default",
  "voice-mute",
  "voice-default",
  "messaging-inbound",
  "messaging-outbound",
  "messaging-mms",
  "messaging-sms",
  "messaging-default",
  "phone-connecting",
  "phone-hangup",
  "phone-error",
  "phone-inbound",
  "phone-outbound",
  "phone-two-way",
  "phone-default",
  "apis-voice",
  "apis-messaging",
  "apis-fax",
  "products-ucaas",
  "products-trunking",
  "products-sd-wan",
  "products-voyant_office",
  "products-voyant_fax",
  "products-emma",
  "products-admin",
  "products-account"
);

.glyph {
  width: #{$glyph-size}px;
  height: #{$glyph-size}px;
  display: inline-block;
  background-image: url("/assets/images/glyphs/Sprite_Glyphs.svg");
  -webkit-filter: invert(23%) sepia(47%) saturate(274%) hue-rotate(172deg)
    brightness(92%) contrast(90%);
  filter: invert(23%) sepia(47%) saturate(274%) hue-rotate(172deg)
    brightness(92%) contrast(90%);

  @each $glyph-name in $glyph-names {
    $i: index($glyph-names, $glyph-name);
    &-#{$glyph-name} {
      background-position-y: #{$i * $glyph-size}px;
    }
  }

  &--no-close {
    &:hover {
      cursor: pointer;
    }
  }
  &--brand-primary {
    -webkit-filter: invert(43%) sepia(79%) saturate(1543%) hue-rotate(203deg)
      brightness(99%) contrast(106%);
    filter: invert(43%) sepia(79%) saturate(1543%) hue-rotate(203deg)
      brightness(99%) contrast(106%);
  }

  &--red {
    -webkit-filter: invert(42%) sepia(85%) saturate(3258%) hue-rotate(325deg)
      brightness(97%) contrast(98%);
    filter: invert(42%) sepia(85%) saturate(3258%) hue-rotate(325deg)
      brightness(97%) contrast(98%);
  }

  &--white {
    -webkit-filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(306deg)
      brightness(108%) contrast(109%);
    filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(306deg)
      brightness(108%) contrast(109%);
  }

  &--grey {
    -webkit-filter: invert(23%) sepia(47%) saturate(274%) hue-rotate(172deg)
      brightness(92%) contrast(90%);
    filter: invert(23%) sepia(47%) saturate(274%) hue-rotate(172deg)
      brightness(92%) contrast(90%);
  }

  &--light-grey {
    -webkit-filter: invert(23%) sepia(47%) saturate(274%) hue-rotate(172deg)
      brightness(92%) contrast(90%);
    filter: invert(89%) sepia(8%) saturate(130%) hue-rotate(185deg)
      brightness(89%) contrast(84%);
  }

  &--green {
    -webkit-filter: invert(78%) sepia(28%) saturate(4879%) hue-rotate(120deg)
      brightness(91%) contrast(91%);
    filter: invert(78%) sepia(28%) saturate(4879%) hue-rotate(120deg)
      brightness(91%) contrast(91%);
  }

  &--aqua {
    -webkit-filter: invert(69%) sepia(47%) saturate(7390%) hue-rotate(155deg)
      brightness(103%) contrast(101%);
    filter: invert(69%) sepia(47%) saturate(7390%) hue-rotate(155deg)
      brightness(103%) contrast(101%);
  }

  &--primary {
    -webkit-filter: invert(45%) sepia(69%) saturate(6559%) hue-rotate(240deg)
      brightness(92%) contrast(100%);
    filter: invert(45%) sepia(69%) saturate(6559%) hue-rotate(240deg)
      brightness(92%) contrast(100%);
  }

  &--charcoal {
    -webkit-filter: invert(42%) sepia(10%) saturate(190%) hue-rotate(178deg)
      brightness(96%) contrast(88%);
    filter: invert(42%) sepia(10%) saturate(190%) hue-rotate(178deg)
      brightness(96%) contrast(88%);
  }

  &--active {
    -webkit-filter: invert(69%) sepia(92%) saturate(953%) hue-rotate(350deg)
      brightness(102%) contrast(101%);
    filter: invert(69%) sepia(92%) saturate(953%) hue-rotate(350deg)
      brightness(102%) contrast(101%);
  }

  &--dark {
    -webkit-filter: invert(23%) sepia(47%) saturate(274%) hue-rotate(172deg)
      brightness(92%) contrast(90%);
    filter: invert(23%) sepia(47%) saturate(274%) hue-rotate(172deg)
      brightness(92%) contrast(90%);
  }

  // Sizing Alterations
  &--scale-down {
    transform: scale(0.65);
  }
  &--scale-up {
    transform: scale(1.25) !important;
  }
  &-no-close,
  &-person-default {
    transform: scale(0.75);
  }

  &-min-width {
    min-width: 24px;
  }

  &--tilt {
    transform: rotate(45deg);
  }
  &--rotate {
    transform: rotate(90deg);
  }
  &--flip {
    transform: rotate(180deg);
  }

  &--search {
    &:hover {
      -webkit-filter: invert(43%) sepia(79%) saturate(1543%) hue-rotate(203deg)
        brightness(99%) contrast(106%);
      filter: invert(43%) sepia(79%) saturate(1543%) hue-rotate(203deg)
        brightness(99%) contrast(106%);
      transform: scale(1.05);
      &:active {
        vertical-align: middle;
        transition: all 0.2s ease-in-out;
        transform: scale(0.85);
        opacity: 0.75;
      }
    }
  }

  // Sizing Alterations
  &-no-close,
  &-person-default {
    transform: scale(0.75);
  }

  &--small {
    transform: scale(0.75);
  }
}
