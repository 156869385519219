.review {
  &__heading {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.43px;
    color: var(--dark);
    margin-bottom: 24px;
  }

  &__block {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);
    margin-bottom: 32px;
    .label {
      font-weight: bold;
    }
  }

  &__user {
    p {
      line-height: 24px;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
