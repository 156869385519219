$gutter: calc($grid-gutter-width / 2);

%grid-column {
  position: relative;
  width: 100%;
  padding-right: $gutter;
  padding-left: $gutter;
}

// .col {
//   flex-basis: 0;
//   flex-grow: 1;
//   max-width: 100%;
// }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @for $i from 1 through $grid-columns {
    .col#{$infix}-#{$i} {
      @extend %grid-column;
    }
  }

  .col#{$infix},
  .col#{$infix}-auto {
    @extend %grid-column;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    // Provide basic `.col-{bp}` classes for equal-width flexbox columns
    .col#{$infix} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col#{$infix}-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; // Reset earlier grid tiers
    }

    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @include make-col($i, $grid-columns);
      }
    }

    .order#{$infix}-first {
      order: -1;
    }

    .order#{$infix}-last {
      order: $grid-columns + 1;
    }

    @for $i from 0 through $grid-columns {
      .order#{$infix}-#{$i} {
        order: $i;
      }
    }

    // `$columns - 1` because offsetting by the width of an entire row isn't possible
    @for $i from 0 through ($grid-columns - 1) {
      @if not($infix == '' and $i == 0) {
        // Avoid emitting useless .offset-0
        .offset#{$infix}-#{$i} {
          @include make-col-offset($i, $grid-columns);
        }
      }
    }
  }
}
