.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 3px;

  &__body {
    flex: 1 1 auto;
    padding: 1.25rem;
    padding: 24px 32px;
    &--empty,
    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    padding: 1.25rem;
    padding-bottom: 0px;
    font-size: 16px;
  }

  &__header {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    border-bottom: 1px solid #eaeaeb;
    padding: 16px 24px;
    font-weight: bold;
    &--modal {
      padding: 0.75rem 1.25rem;
    }
    &--between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--wButton {
      @extend .card__header--between;
      padding: 8px 24px;
    }
  }

  &--with-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  &__footer {
    padding: 0px 32px 24px;
  }

  &--settings {
    margin-bottom: 32px;
    min-height: 508px;
    .card__footer {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .form {
      label {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  &__empty-message {
    text-align: center;
    font-size: 0.875rem;
    color: rgba(55, 67, 82, 0.4);
  }

  &__instructions {
    font-size: 0.8rem;
    margin-bottom: 1em;
    color: rgba(55, 67, 82, 0.4);
  }

  @each $class in map-keys($theme) {
    &--#{$class} {
      border: 1px solid var(--#{$class});
      .card {
        &__header {
          background-color: var(--#{$class});
          border-bottom: none;
          color: white;
          padding: 10px 24px;
        }
      }
    }
  }
}
