$gutter: calc($grid-gutter-width / 2);

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -#{$gutter};
  margin-right: -#{$gutter};
  @include clearfix;

  &.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*='col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
