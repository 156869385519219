.members {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;

  &.active {
    border-bottom: solid 1px #979797;
    padding-bottom: 24px;
  }
  div {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    // padding-right: 20%;
    i.glyph {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
