.input {
  &__group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    .form__control {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
    }
  }

  &__group-text {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    .btn--input {
      height: 38px;
      padding: 8px 10px;
    }
  }
}
