.form {
  &__title {
    font-family: "Lato";
    font-size: 24px;
    color: var(--dark);
    font-weight: normal;
  }
  &__inline {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: center;

    .form__control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      transition: border-color 0.2s linear;
      &.mat-form-field {
        width: auto;
      }

      &:read-only {
        background-color: #f8f9fa;
      }
    }
  }

  &__horizontal {
    .form__group {
      .form-control {
        font-size: 12px;
      }
      margin-bottom: 32px;

      &--fuse-label {
        position: relative;

        label {
          position: absolute;
          top: -7px;
          left: 15px;
          font-size: 12px;
          padding: 0px 5px;
          color: rgba(55, 67, 82, 0.4);
          margin-bottom: 0px;
          background-color: #fff;
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }
      }

      &--has-error {
        label {
          color: var(--danger);
        }
        .form__control {
          border-color: var(--danger);
        }

        .error-message {
          font-size: 12px;
          color: var(--danger);
          animation: fadeIn 0.2s linear forwards;
        }
      }

      &--error {
        color: var(--danger);
      }
    }
  }

  &__group {
    margin-bottom: 1.5rem;

    &--error {
      $danger: var(--danger);
      position: relative;

      label {
        color: $danger;
      }

      .form__control {
        border-color: $danger;
      }

      .error-message {
        font-size: 12px;
        color: $danger;
        animation: fadeIn 0.2s linear forwards;
      }

      &.row,
      &[class*="col"] {
        .error-message {
          left: 15px;
        }
      }
    }

    small {
      font-size: 10px;
    }
  }

  &__radio {
    label:not(.form__radio--label) {
      font-size: 16px;
      font-weight: normal;
      color: var(--dark);
      text-transform: none;
    }
  }

  &__control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #979797;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &--lg {
      height: 51px;
    }

    &--xs {
      height: 27px;
    }

    &:read-only:not(ng-select) {
      background-color: #f8f9fa;
    }

    &:focus,
    &:hover,
    &:active {
      outline: none;
    }
  }

  textarea.form__control {
    min-height: 75px;
    resize: vertical;
  }

  &__checkbox {
    &.mat-checkbox {
      .mat-checkbox-label {
        color: #344353 !important;
        font-size: 16px;
        font-weight: normal;
      }
      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
      }

      &:not(.mat-checkbox-disabled).mat-accent
        .mat-checkbox-ripple
        .mat-ripple-element {
        background-color: var(--brand-primary);
      }

      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: var(--brand-primary);
        }
      }
    }
  }

  &__col-form-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }

  &__label {
    font-weight: normal;
    margin-bottom: 10px;
    font-size: 10px;
    text-transform: uppercase;
  }

  &__control-group {
    position: relative;

    + .form__control {
      padding-right: 36px;
    }
  }
  &__control {
    &--group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      .form__control {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-left: -1px;
        padding-right: 12px;
        border-right: 0px;
        &:read-only {
          background-color: #ffffff;
          color: #37435266;
        }
      }
    }
    &--text {
      display: flex;
      align-items: center;
      padding: 0.375rem 0.5625em;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #979797;
      border-left: 0px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  &__group {
    &--labels {
      margin-bottom: 0px;
    }
  }
}

::ng-deep .mat-form-field-infix {
  width: auto !important;
}

input {
  @include placeholder {
    color: map-get($input, border-color);
  }
}

label {
  // font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0.43px;
  color: var(--dark);
}
