// Main font
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,800,900");
@import "~@angular/cdk/overlay-prebuilt.css";
// mobile styles take presedense
@import "./mobile/buttons";
@import "./global/index";
@import "./components/index";

// Global Styles

* {
  margin: 0;
  padding: 0;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    max-height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  color: var(--dark);
  font-family: "Lato", sans-serif;

  @each $class, $color in $theme {
    --#{$class}: #{$color};
  }

  @each $class, $color in $brand {
    --brand-#{$class}: #{$color};
  }
}

// hide chrome styling
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rounded-circle {
  border-radius: 50%;
}

.ng-select.select {
  color: var(--dark);
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid var(--dark);
}

.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #ccc;
  left: 0;
  padding: 6px;
  .ng-dropdown-panel-items {
    max-height: 140px !important;
  }
}

.ng-select {
  &.no-controlls {
    .ng-select-container {
      .ng-arrow-wrapper {
        display: none;
      }
    }
  }
  .ng-value-container {
    padding-right: 10px;
  }

  &.ng-dirty {
    .ng-value-container {
      .ng-placeholder {
        display: none;
      }
    }
  }

  &.show-scroll {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .ng-arrow-wrapper {
    .ng-arrow {
      pointer-events: none;
      display: inline-block;
      height: 0;
      width: 0;
      position: relative;

      border-color: var(--dark) transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
      margin-left: 5px;
    }
  }
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent var(--dark);
  border-width: 0 5px 5px;
}

.ng-select.form__control.hide-clear {
  .ng-clear-wrapper {
    display: none;
  }
}

%voyant-input {
  position: relative;
  font-weight: normal;
  padding-left: 28px;
  margin: 2px 0px;

  &:hover {
    cursor: pointer;
    span {
      border-width: 2px;
    }
  }

  span {
    position: absolute;
    top: 2px;
    left: 0px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid var(--dark);
  }
}

.voyant-radio {
  @extend %voyant-input;
  display: flex;
  align-items: center;
  height: 18px;
  margin-right: 24px;
  text-transform: none !important;
  font-size: 1rem !important;

  input[type="radio"]:checked ~ span {
    background-color: var(--dark);
  }
}

.voyant-radio-check {
  @extend %voyant-input;
  input[type="checkbox"]:checked ~ span {
    background-color: var(--dark);
  }
}

.voyant-check {
  @extend %voyant-input;
  span {
    border-radius: 1px;
  }
  input[type="checkbox"]:checked ~ span {
    // position: relative;
    &:before {
      content: "\2713";
      position: absolute;
      top: -3px;
      left: 1px;
    }
  }
}

.link {
  color: var(--secondary);
  &:hover {
    cursor: pointer;
  }
}

.click {
  user-select: none;
  &:hover {
    cursor: pointer;
  }
}

voyant-root {
  display: block;
  height: 100vh;
}

.b-1 {
  border-top: 1px solid #eaeced;
}

.br-1 {
  border-right: 1px solid #eaeced;
}
