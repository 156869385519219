.tabs {
  list-style: none;
  margin: 0px -1rem;

  li {
    display: inline-block;
    padding: 1rem;
  }

  &__underline {
    li {
      font-size: 20px;
      &:hover {
        cursor: pointer;
      }
      > span.active {
        border-bottom: 3px solid var(--brand-secondary);
      }
      > a {
        text-decoration: none;
        color: var(--dark);
        &.active {
          border-bottom: 3px solid var(--brand-primary);
        }
      }
    }
  }
}
