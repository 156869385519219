.badge {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: 3px 8px;
  border-radius: 10rem;
  color: #fff;
  font-size: 10px;
  font-weight: bold;

  @each $class, $value in $theme {
    &__#{$class} {
      background-color: var(--#{$class}) !important;
      border-color: var(--#{$class}) !important;
      color: #fff;

      &:hover {
        background-color: darken($value, 5%);
        border-color: darken($value, 5%);
      }
    }
  }

  &__light-grey {
    color: var(--dark);
    background-color: #eaeced;
    &:hover {
      background-color: darken(#eaeced, 5%);
      border-color: darken(#eaeced, 5%);
    }
    .glyph {
      filter: none;
    }
  }

  &--square {
    border-radius: 0px;
  }

  &--lg {
    font-size: 14px;
    padding: 4px 8px;
  }

  .glyph {
    margin-left: 5px;
    zoom: 0.6;
    @extend .glyph--white;

    &.glyph-no-close:hover {
      cursor: pointer;
      @extend .glyph--red;
    }
  }
}
