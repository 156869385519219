.form {
  &__control--mat {
    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        top: 5px;
        background-color: map-get($input, 'background-color');
        color: map-get($input, 'border-color');

        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
          border-radius: map-get($input, 'border-radius');
        }
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-width: map-get($input, 'border-width');
        }

        &.mat-form-field-outline-thick {
          color: map-get($input, 'border-color-hover');
        }
      }
      &.mat-focused {
        .mat-form-field-outline {
          &.mat-form-field-outline-thick {
            color: map-get($input, 'border-color-focused');
          }
        }
      }
      .mat-form-field-wrapper {
        margin: 0px;
      }
    }

    .mat-form-field {
      &-wrapper {
        margin: 0px;
        padding-bottom: 0px;
      }

      &-infix {
        min-width: 150px;
        max-width: 500px;
        width: 100%;
        border-top: 0px;
        padding: 13px 0px 10px 0px;
      }

      // ::ng-deep .mat-form-field-infix {
      //   width: auto !important;
      // }
    }

    .mat-select {
      &-arrow-wrapper {
        transform: translateY(10%) !important;
      }
    }
  }

  .mat-radio-group {
    .mat-radio-button {
      display: block;
      margin: 5px 0px;
      label.mat-radio-label {
        font-weight: normal;
      }
    }
  }
}
