.mat-menu-panel {
  .mat-menu-content:not(:empty) {
    padding-top: 12px;
    padding-bottom: 12px;
    .mat-menu-item {
      display: flex;
      align-items: center;
      font-size: 16px;
      height: 38px;
      line-height: 38px;
      padding: 0px 16px;
      // transition: all 0.1s linear;
      .glyph {
        transform: scale(1);
        margin-right: 8px;
        // transition: all 0.1s linear;
      }

      &:hover:not([disabled]),
      &.cdk-keyboard-focused {
        background: #fff;
        color: var(--brand-primary);

        .glyph {
          filter: var(--brand-filter);
        }
      }
    }
  }
}

$triangle: 8px;
.mat-menu-panel.header-dropdown {
  min-width: 246px;
  margin-top: 10px;
  overflow: initial;
  .mat-menu-content {
    position: relative;
    border: 1px solid #ebeced;
    &:after,
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -#{$triangle - 1px};
      right: 70px;
      width: 0;
      height: 0;
      border-left: $triangle solid transparent;
      border-right: $triangle solid transparent;
      border-bottom: $triangle solid #fff;
      border-radius: 5px;
    }
    &:before {
      z-index: 999;
    }

    &:after {
      z-index: -1;
      right: 68px;
      top: -#{$triangle};
      border-left: $triangle + 2px solid transparent;
      border-right: $triangle + 2px solid transparent;
      border-bottom: $triangle + 2px solid #ebeced;
    }
  }
}
