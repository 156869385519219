////////////////////////////////////
///////////// COLORS ///////////////
////////////////////////////////////

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex !default;

$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);
//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

$screen-sm: map-get($grid-breakpoints, "sm");
$screen-md: map-get($grid-breakpoints, "md");
$screen-lg: map-get($grid-breakpoints, "lg");
$screen-xl: map-get($grid-breakpoints, "xl");

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;

$theme: (
  "primary": rgb(106, 74, 235),
  "secondary": #0bc7e1,
  "success": #08ce6e,
  "danger": #dc3545,
  "warning": #ffc107,
  "light": #f8f9fa,
  "dark": #374352,
  "grey": #979797,
  "light-grey": #eaeced,
  "blue": #004c97,
  "white": #ffffff,
  "link": transparent,
  "brand-primary": #004c97,
  "placehoder": #efefef,
);

$brand: (
  "primary": rgb(72, 50, 163),
  "primary-darken": rgb(56, 39, 124),
  "primary-lighten": rgba(72, 50, 163, 0.1),
  "secondary": rgba(11, 199, 225),
  "base": rgb(1, 27, 53),
  "filter": invert(45%) sepia(69%) saturate(6559%) hue-rotate(240deg)
    brightness(92%) contrast(100%),
);

$input: (
  border-width: 1px,
  border-color: #ced4da,
  background-color: #ffffff,
  border-radius: 0px,
  border-color-hover: var(--dark),
  border-color-focused: var(--primary),
);
