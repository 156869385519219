// Fade In
@-webkit-keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1; }
}


@-webkit-keyframes scale-in-center {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
  }
}

@mixin animation($animation-props) {
  -webkit-animation: $animation-props;
          animation: $animation-props;
}