.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  &__label {
    word-wrap: none;
    font-weight: bold;
    flex: 1;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    &__number {
      p:first-child {
        margin-bottom: 10px;
      }
    }
    i.glyph:hover {
      cursor: pointer;
    }
  }
}
