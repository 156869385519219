.bg {
  @each $class, $value in $theme {
    &--#{$class} {
      background-color: $value;
    }
  }
}

.bg {
  @each $class, $value in $brand {
    &--brand-#{$class} {
      background-color: $value;
    }
  }
}

.bg--primary {
  background-color: var(--brand-primary);
}

.text {
  @each $class, $value in $theme {
    &--#{$class} {
      color: var(--#{$class}) !important;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--grey {
    color: #979797;
  }

  &--help {
    font-size: 10px;
    margin-top: 6px;
  }

  &--hint {
    font-size: 12px;
    margin-top: 15px;
  }

  &--helper {
    padding-top: 34px;
    margin-bottom: -8px;
  }
}
