.modal.modal-right {
  .modal {
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      pointer-events: auto;
      background-color: #fff;
      // background-clip: padding-box;
      outline: 0;

      .close {
        position: absolute;
        left: -132px;
        top: 35px;
        padding: 10px 20px;
        background-color: #fff;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1;
        color: var(--dark);
        text-shadow: 0 1px 0 #fff;
        border: 0;
        appearance: none;

        &:not(:disabled):not(.disabled):hover {
          opacity: 1;
        }
      }
    }
  }
}

.preview {
  &__title {
    font-size: 24px;
    text-align: center;
    margin: 18px 0px 35px;
  }
  &__content {
    margin: 0px 30px;
  }
  &__label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 18px;
  }

  &__details {
    font-size: 18px;
    margin: 25px 0px;

    > .row:not(:last-child) {
      margin-bottom: 25px;
    }

    &--with-controls {
      padding-bottom: 20px;
    }
  }

  &__controls {
    margin: 25px;
    &--sticky {
      position: absolute;
      width: calc(100% + 62px);
      bottom: 0px;
      left: 0px;
      margin: 0px -31px;
    }

    &--edit {
      display: flex;

      button {
        &:first-child {
          flex: 1;
        }
        &:last-child {
          flex: 2;
        }
      }
    }
  }

  &__image {
    margin: 65px 0px 25px;
    .col {
      display: flex;
      justify-content: center;
    }
  }
}

.modal__container {
  min-height: 341px;
  position: relative;
  padding-bottom: 32px;
  padding-top: 32px;

  &-controls {
    margin-bottom: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    > div {
      width: 100%;
    }
  }
}

.modal {
  position: relative !important;
  &.modal-right {
    .mat-dialog {
      &-container {
        padding: 0px 30px;
        border-radius: 0px;
        overflow: initial;
      }
      &-content {
        min-height: 100vh;
        padding: 2rem 0px;
      }
    }

    &__header,
    &__footer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem;
    }
  }

  &.modal-center {
    .modal {
      &__header {
        display: flex;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid rgba(55, 67, 82, 0.1);
        font-weight: 600;
        padding-top: 0px;
        padding-bottom: 20px;
        font-size: 16px;
        text-transform: uppercase;
        .glyph-no-close {
          position: absolute;
          right: 0px;
          top: -3px;
          transform: scale(1);
        }
      }
    }

    [class*="col-"] {
      padding-left: 12px;
      padding-right: 12px;
    }

    .mat-dialog-container {
      min-height: calc(472px - 48px);
      // padding: 24px 45px !important;
    }

    .mat-dialog-content {
      padding: 0px 48px;
    }
  }

  &.modal-center.modal-confirmation {
    .mat-dialog-container {
      min-height: 0px;
    }
  }

  &__container {
    label {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.43px;
      color: var(--dark);
      text-transform: uppercase;
    }
  }
}
