.status {
  position: relative;
  &:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
    position: relative;
    top: 2px;
  }

  @each $class, $value in $theme {
    &__#{$class} {
      &:before {
        background-color: var(--#{$class});
      }
    }
  }
}
