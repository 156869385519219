.icon-label {
  display: flex;
  align-items: center;
  &__icon {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    width: 14px;
    height: 11px;

    &-bg,
    .glyph {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &-bg {
      content: "";
      background-color: var(--blue);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      transform: translate(-50%, -50%);
    }

    .glyph {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }
}
