@import "../global/animations";
table {
  border-collapse: collapse;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  // text-align: left;
  // opacity: 0;
  // will-change: opacity;
  // animation: fadeIn 0.2s linear forwards;

  &__responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  td,
  th {
    vertical-align: middle;
    padding: 1rem 0.75rem;
    border: none;
    font-size: 12px;
    white-space: nowrap;
    text-align: left;
    min-width: 150px;
    position: relative;

    > .glyph {
      position: absolute;
      left: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      zoom: 0.8;
    }

    &.checkbox {
      min-width: 0px;
      max-width: 30px;
    }
  }

  th {
    color: #fff;
    text-transform: uppercase;
  }

  &--brand-primary {
    th {
      background-color: var(--brand-primary);
    }
  }

  &--black {
    th {
      background-color: var(--dark);
    }
  }

  tbody {
    tr {
      background-color: #fff;
      border-bottom: 1px solid lighten(map-get($theme, "dark"), 65%);
      &.selected {
        background: rgba(0, 187, 225, 0.1);
      }
    }
  }

  &__striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &__hover {
    tbody {
      tr:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &__lg {
    width: 250px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
