$triangle: 5px;
.voyant-tooltip {
  /* your own custom styles here */
  /* e.g. */
  color: var(--dark) !important;
  overflow: visible !important;
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(55, 67, 82, 0.2);
  border: solid 1px rgba(55, 67, 82, 0.1);
  font-size: 12px;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -#{$triangle};
    left: 50%;
    width: 0;
    height: 0;
    border-left: $triangle solid transparent;
    border-right: $triangle solid transparent;
    border-bottom: $triangle solid #fff;
    border-radius: 5px;
    transform: translateX(-50%);
  }
  &:before {
    z-index: 999;
  }

  &:after {
    z-index: -1;
    left: 50%;
    top: -#{$triangle + 2px};
    border-left: $triangle + 2px solid transparent;
    border-right: $triangle + 2px solid transparent;
    border-bottom: $triangle + 2px solid #ebeced;
  }

  &--right {
    &::before,
    &:after {
      display: none;
    }
  }
}
