.page {
  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 0px;
  }
  &__container {
    padding: 45px 10px 25px;
    position: relative;
  }

  &__section {
    display: block;
    margin-bottom: 30px;
  }
}

.data {
  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75px;

    > * {
      color: #fff;
    }
    .glyph {
      opacity: 0.5;
      zoom: 1.5;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 3px;
    }
    small {
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 10px;
    }
    h3 {
      margin-bottom: 0px;
      font-size: 55px;
      font-weight: 300;
    }
  }
}
